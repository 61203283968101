import React, { useEffect, useRef } from "react";
import Lottie from "react-lottie";
import animationData from "./Animation - 1738151576471.json";

const AuthAnimation = ({ isValidating, isAuthenticated }) => {
	// const lottieRef = useRef();

	// useEffect(() => {
	// 	if (lottieRef.current) {
	// 		if (isValidating) {
	// 			// Play only first few frames in a loop during validation
	// 			lottieRef.current.playSegments([0, 30], true);
	// 		} else if (isAuthenticated) {
	// 			// Play the complete animation once authenticated
	// 			lottieRef.current.playSegments([0, 120], true);
	// 		}
	// 	}
	// }, [isValidating, isAuthenticated]);

	return (
		<Lottie
			// ref={lottieRef}
			options={{
				loop: true,
				autoplay: true,
				animationData: animationData,
				rendererSettings: {
					preserveAspectRatio: "xMidYMid slice",
				},
			}}
			height={150}
			width={150}
		/>
	);
};

export default AuthAnimation;
