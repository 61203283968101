//

//
//

// export const api_addQrCode = e + "/pm/qrcodemanagement/addQrCode";
// export const api_getQrCodes = e + "/pm/qrcodemanagement/getQrCodes";
// //
// //
// // SURVEY
// export const api_getSurveyDevices = e + "/pm/getSurveyDevices";
// export const api_deleteSurveyDevices = e + "/pm/deleteSurveyDevice";
// export const api_newSurveyDevice = e + "/pm/newSurveyDevice";
// export const api_getAllSurveys = e + "/pm/getAllSurveys";
// export const api_getSurveySubmissions = e + "/pm/getSurveySubmissions";

// export const api_newsurvey = e + "/pm/newSurvey";
// export const api_getsurveydata = e + "/surveydata/";
// //
// //
// //
// export const api_checkidexists = e + "/pm/checkidexists";
// export const api_checkemployeeidexists = e + "/pm/heckemployeeidexists";
// //
// //
// //
// // ------------------ STAFF
// export const api_newStaff = e + "/pm/newStaff";
// export const api_getStaff = e + "/pm/getStaff";
// export const api_updateStaff = e + "/pm/updateStaff";
// export const api_deleteStaff = e + "/pm/deleteStaff";

// export const api_getManager = e + "/pm/getManager";

// // ------------------ Brands
// export const api_addBrand = e + "/brands/addBrand";
// export const api_getBrand = e + "/brands/getBrands";
// export const api_updateBrand = e + "/brands/updateBrand";
// export const api_deleteBrand = e + "/brands/deleteBrand";

// // ------------------ AppProperties
// export const api_addAppProperties = e + "/AppProperties/add";
// export const api_getAppProperties = e + "/AppProperties/get";
// export const api_updateAppProperties = e + "/AppProperties/update";
// export const api_deleteAppProperties = e + "/AppProperties/delete";

// // ------------------ Malls
// export const api_addMall = e + "/malls/add";
// export const api_getMall = e + "/malls/get";
// export const api_updateMall = e + "/malls/update";
// export const api_deleteMall = e + "/malls/delete";
// //
// // ------------------ Retailer
// export const api_addRetailer = e + "/retailers/add";
// export const api_getRetailer = e + "/retailers/get";
// export const api_updateRetailer = e + "/retailers/update";
// export const api_deleteRetailer = e + "/retailers/delete";
// //
// // ------------------ Products
// export const api_addProduct = e + "/products/add";
// export const api_getProduct = e + "/products/get";
// export const api_updateProduct = e + "/products/update";
// export const api_deleteProduct = e + "/products/delete";
// //
// // ------------------ Customers
// export const api_addCustomers = e + "/customers/add";
// export const api_getCustomers = e + "/customers/get";
// // export const api_updateCustomers = e + "/sales/update";
// // export const api_deleteCustomers = e + "/sales/delete";
// //
// //
// // ------------------ Sales
// export const api_addSales = e + "/sales/add";
// export const api_getSales = e + "/sales/get";
// export const api_updateSales = e + "/sales/update";
// export const api_deleteSales = e + "/sales/delete";
// //
// ///

// ------------------ QR COdes

let e = "https://us-central1-tetricepm-5f972.cloudfunctions.net/app";
// let e = "http://127.0.0.1:5001/tetricepm-5f972/us-central1/app";
//
export const api_login = e + "/pm/login";

export const api = e;

export const api_crudCreate = api + "/pm/c";
export const api_crudRead = api + "/pm/r";

export const api_crudReadUnass = api + "/pm/runass";

export const api_crudUpdate = api + "/pm/u";
export const api_crudDelete = api + "/pm/d";
export const api_crudDeleteWhere = api + "/pm/crudDeleteWhere";

export const api_crudWithImage = api + "/pm/cwimg";
export const api_crudWithHtml = api + "/pm/cwhtml";
export const api_crudDeleteProposalPage = api + "/pm/deleteProposalPage";

export const api_crudDeleteDocument = api + "/pm/cdelimg";
export const api_sendBasicEmail = api + "/pm/sendBasicEmail";
export const api_sendUserIdEmail = api + "/pm/sendUserIdEmail";

export const api_sendDiscordMessage = api + "/pm/sendDiscordMessage";

export const api_getUsers = api + "/pm/users";
export const api_createPostApproval = api + "/pm/createPostApproval";
export const api_creatSurveyFeedback = api + "/pm/creatSurveyFeedback";

//
export const api_contentApprovalRequest = api + "/pm/contentapprovalopen";

export const api_crudGetProjects = api + "/pm/projects";
export const api_crudGetTasks = api + "/pm/tasks";

export const api_crudDeleteWithImage = api + "/pm/cdelimg";

export const api_validateShareToken = api + "/pm/validateShareToken";
